// Main variables
$background-color: rgb(0,0,0) !default;
$accent-color: rgb(0, 204, 204) !default;
$font-family: "Monospace" !default;
$font-family-promo: $font-family !default;
$text-color: mix($accent-color,invert($background-color,80%),2%)!default;
$text-color-strong: mix($accent-color,invert($background-color,90%),2%)!default;
$shadow-color: rgba(0,0,0, 0.3) !default;

$border-radius: 5px !default;

$hr-from: mix($accent-color,$background-color,5%);
$hr-to: $accent-color;
$hr: linear-gradient(0.25turn, $hr-from, $hr-to, $hr-from);
$hr-45: linear-gradient(0.45turn, $hr-from, $hr-to, $hr-from);

// Body
$body-background: $background-color !default;

$bonus-slider-background: url(../public/brand/images/bg-dashboard.jpg) no-repeat center !default;

$jackpot-slider-background: mix($accent-color,$background-color,12%) !default;
$jackpot-slider-border: $hr 2 0 2 0 !default;

$sidebar-background: mix($accent-color,$background-color,5%) !default;
$sidebar-section-background: $jackpot-slider-background !default;


$promo-section-border:$hr-45 1 1 1 1;
$promo-card-background:  mix($accent-color,$background-color,5%) !default;

$page_menu_background: mix($accent-color,$background-color,7%) !default;
$page_contents_background: mix($accent-color,$background-color,5%) !default;


$modal_background: mix($accent-color,$background-color,5%) !default;

// Footer
$footer-background: mix($accent-color,$background-color,5%) !default;
$footer-hr-border: $hr 1 0 1 0 !default;
//$footer-background: rgb(var(--primaryColor)) !default;