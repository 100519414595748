@use "sass:color";
@use "sass:math";

@mixin recolor($color: #000, $opacity: 1) {
  $r: math.div(red($color), 255);
  $g: math.div(green($color), 255);
  $b: math.div(blue($color), 255);
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

  // color filter
  $svg-filter-id: "recolor";
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}

@import 'vars';
@import 'default_vars';

.App {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body {
  margin: 0;
  background: $body-background;
  font-family: $font-family;
}

/*table, th, td {*/
/*    border:1px solid black;*/
/*}*/
.btn {
  border: transparent;
  background-color: transparent;
  font-size: 14px;
  text-transform: uppercase;
  /*font-style: italic;*/
  font-weight: 700;
  margin-right: 15px;
  color: $text-color;
  height: 40px;
  min-width: 50px;
  font-family: $font-family;
  cursor: pointer;
  padding: 0 10px 0 10px;
  transition: 0.3s ease-in-out;

  :hover {
    color: $accent-color;
  }
}

/*///////////Jackpot Slider//////////*/
.jackpot_wrapper {
  font-family: $font-family;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  background: $jackpot-slider-background;
  border-image: $jackpot-slider-border;
}

.jackpot_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.jackpot {
  line-height: 120%;
  font-weight: 900;
  font-family: $font-family-promo;
  color: $text-color-strong;
  -webkit-animation: arrowInOut 4s infinite;
  animation: arrowInOut 4s linear infinite;

  &.desc {
    font-size: clamp(20px, 1.5vw, 35px);
    justify-self: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    /*font-style: italic;*/
    color: mix($text-color, $background-color, 70%);
  }

  &.value {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: clamp(30px, 3.5vw, 60px);
    color: $accent-color;
  }

  &.name {
    font-size: clamp(20px, 2.5vw, 40px);
  }
}


@-webkit-keyframes arrowInOut {
  0%, 100% {
    opacity: 0;
  }
  30%, 80% {
    opacity: 1;
  }
}

@keyframes arrowInOut {
  0%, 100% {
    opacity: 0;
  }
  30%, 80% {
    opacity: 1;
  }
}


.jackpot_slider {
  border-radius: 20px;
  padding: 30px 0 30px 0;
  width: 89%;
  display: flex;
  position: relative;
  max-width: 2500px;
}

.jackpot_left {
  width: 30%;
  display: flex;
  justify-content: center;
}

.jackpot_right {
  display: flex;
  width: 70%;
  position: relative;
}

.jackpot_right > .swiper {
  overflow: hidden !important;
}

.swiper {
  width: 90%;
  overflow: visible !important;
}

.swiper_item_jackpot {
  cursor: pointer;
  height: 15vw;
  width: 15vw;
  max-height: 270px;
  max-width: 270px;
  border-radius: 10px;
  transform: scale(0.9);
}

.swiper_item_jackpot:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.swiper_item_jackpot img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

/* ///////////// swiper carousel ///////////// */
.swiper_carousel {
  position: relative;
  display: flex;
  /*width: 95%;*/
  /*margin-left: 2.5%;*/
}

.swiper_item_carousel {

  max-height: 350px;
  max-width: 350px;
  border-radius: 10px;
  transform: scale(0.9);
}

.swiper_item_carousel img {
  cursor: pointer;
  border-radius: $border-radius;
  width: 100%;
  height: 100%;
}

.swiper_item_carousel:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.arrow.basic {
  z-index: 5;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  max-width: 180px;
  min-width: 40px;
  height: 100%;
  transform: scale(0.9);
  border-radius: 10px;
  position: absolute;
  filter: none;
  transition: opacity 0.5s ease-in-out;

  > img {
    opacity: 0;
    transition: 0.3s ease-in-out;
    min-width: 25px;
    width: 2vw;
    max-width: 50px;
    min-height: 25px;
    height: 2vw;
    max-height: 50px;
    filter: invert(0.8);
    margin-right: -3px;
  }
}

.arrow.basic.active > img {
  opacity: 1
}

.arrow.basic.prev {
  left: -13px;
  cursor: pointer;
  top: 0;
  transform: rotate(180deg) scale(0.9);
  background-color: rgba(0, 0, 0, 0.74);
}

.arrow.basic.next {
  right: -13px;
  border-radius: 10px;
  cursor: pointer;
  top: 0;
  background-color: rgba(0, 0, 0, 0.74);
}


/* ///////////// swiper ///////////// */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /*background: #fff;*/
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  position: absolute;
  filter: drop-shadow(0px 0px 4px black);
  @include recolor($accent-color);

  &.prev {
    left: -13px;
    cursor: pointer;
    top: 45%;
    transform: rotate(180deg);
  }

  &.next {
    right: -13px;
    border-radius: 50px;
    cursor: pointer;
    top: 45%;
  }

  img {
    width: 70%;
    height: 70%;
    filter: invert(0.8);
    margin-right: -3px;
  }
}

.slider-wrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.slider-wrapper:last-child {
  margin-bottom: 50px;
}

.slider-title {
  display: flex;
  position: absolute;
  top: -29px;

}

.slider-title > span {
  font-size: clamp(13px, 3vw, 25px);
  line-height: 130%;
  font-weight: 900;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  animation: showAnimation 1s ease .5s forwards;
  text-transform: capitalize;
  /*font-style: italic;*/
  color: $text-color-strong;
  font-family: $font-family;
  margin-left: 6vw;
  letter-spacing: -1px !important;


}

.slider-title > span > span {
  font-size: clamp(13px, 3vw, 25px);
  letter-spacing: -1px;
  line-height: 130%;
  font-weight: 900;
  display: inline-block;
  margin-left: 5px;
}

/* ///////////// Promotion Page ///////////// */
.promotions_wrapper {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin-bottom: 40px;
  margin-top: 32px;
}

.promotion_section {
  display: flex;
  max-width: 600px;
  min-height: 230px;
  max-height: 220px;
  height: 15vw;
  width: 40vw;
  min-width: 300px;
  margin: 10px;
  padding: 20px;
  background-color: $promo-card-background; //rgb(0, 23, 23);
  box-shadow: 0 4px 6px $shadow-color;
  border-style: solid;
  border-width: 2px;
  border-image: $promo-section-border;

}

.promo_left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pCard_left {
  display: flex;
  flex-direction: column;
}

.promo_right {
  width: 40%;
  height: 100%;
  position: relative;

}

.pCard_right {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.pCard_right .btn.st {
  padding: 20px;
  color: $text-color-strong;
}

.promo_right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pCard_right a {
  font-size: clamp(11px, 2vw, 14px);
  line-height: 120%;
  font-weight: 600;
  color: $text-color-strong;
  cursor: pointer;
  text-decoration: underline;
}


.pCard_name {
  margin-top: 15px;
  font-size: clamp(10px, 2vw, 14px);
  line-height: 140%;
  font-weight: 700;
  color: $accent-color;
  letter-spacing: 3px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.pCard_details {
  font-size: clamp(28px, 3vw, 30px);
  line-height: 120%;
  font-weight: 900;
  /*font-style: italic;*/
  color: $text-color-strong;
  text-transform: uppercase;
}

/* ///////////// Leader Board ///////////// */


.leader_board_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  line-height: 120%;
  font-weight: 900;
  color: $text-color-strong;
  margin-bottom: 2em;

  h1 {
    font-size: clamp(24px, 3vw, 26px);
    font-family: $font-family-promo;
    text-transform: uppercase;
  }
}

.updated_time {
  font-size: clamp(13px, 1vw, 16px);
  color: $text-color-strong;
  text-transform: capitalize;
  margin-bottom: 1em;
}

.winner_board_dynamic {
  table-layout: fixed; /* optional, for equal spacing */
  align-self: center;
  min-width: 340px;
  width: 35vw;
  border-spacing: 0;
  position: relative;
  left: calc(clamp(12px, 3vw, 20px) * -1)
}

.winner_board_dynamic th {
  font-size: clamp(12px, 3vw, 20px);
  text-transform: uppercase;
  border-bottom: 2px solid transparentize(invert($background-color), .7) !important;
  color: $accent-color;
}

.winner_board_dynamic td {
  font-size: clamp(13px, 1vw, 16px);
  text-transform: capitalize;
  color: $text-color;

  &:first-of-type {
    color: $text-color-strong
  }
}

.winner_board_dynamic td,
.winner_board_dynamic th {
  height: 50px;
  background-color: $jackpot-slider-background;
  border-left: 2px solid transparentize(invert($background-color), .8);
  border-bottom: 1px solid transparentize(invert($background-color), .9);
}

.winner_board_dynamic th:first-of-type {
  background-color: transparent;
  color: $accent-color;
}

.winner_board_dynamic tr > *:nth-child(2) {
  border-left: none !important;
}

.winner_board_dynamic thead tr th:nth-of-type(2) {
  border-top-left-radius: 20px;
}

.winner_board_dynamic thead tr th:nth-last-of-type(1) {
  border-top-right-radius: 20px;
}

.winner_board_dynamic tbody tr:last-of-type td:nth-of-type(1) {
  border-bottom-left-radius: 20px;
}

.winner_board_dynamic tbody tr:last-of-type td:nth-last-of-type(1) {
  border-bottom-right-radius: 20px;
}

.winner_board_dynamic th:first-of-type {
  border: none !important;
  width: calc(clamp(12px, 3vw, 20px) * 2);
}
.lb-img{
  height: 100%;
  @include recolor($accent-color)
}

/* ///////////// Middle Contents ///////////// */
.btn.pg {
  width: 100%;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  display: block;
  min-height: 44px;
  padding: 10px 18px 35px 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: $accent-color;
  white-space: nowrap;
  border-radius: 48px;
  transition: 0.2s ease;
  text-align: left;
  margin: 0px 0 0px 0;
  border-style: solid;
  border-width: 1px;
  border-image: $hr 0 0 1 0;
  opacity: 0.4;

  &.active, &:hover {
    font-weight: 900;
    opacity: 1;
  }

}


/* It's removing the border from the last option in the dropdown. */
.pages_options :last-child {
  border: none;
}

/* This is the middle content area. It is a container that is centered on the page. */
.middle_content {
  height: auto;
  margin-top: 70px;
  max-width: 1500px;
  width: 100%;
  align-self: center;
  z-index: 0;
}

.middle_bg_pic {
  position: absolute;
  top: 70px;
  min-height: 350px;
  height: 25vw;
  z-index: -2;
}

.middle_content.pages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.pages_options {
  padding: 20px 20px 20px 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 50px 5px 50px 0;
  height: fit-content;
  min-width: 250px;
  max-width: 320px;
  background-color: $page_menu_background;
  border-radius: 10px;
}

.middle_bg_pic:before {
  content: "";
  background-image: url(../public/brand/images/bg-dashboard.jpg);
  position: absolute;
  background-repeat: repeat;
  background-size: cover;
  background-position-y: center;
  box-shadow: inset 0 -100px 81px 6px $background-color;
  top: 0;
  left: 5%;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: .4;
}

.pages_contents {
  display: flex;
  justify-content: center;
  margin: 50px 0 50px 5px;
  width: 65%;
  border-radius: 30px;
  color: $text-color-strong;
  background-color: $page_contents_background;
  backdrop-filter: blur(20px);
  font-family: $font-family;
}

.pages_content_wrapper {
  max-width: 650px;
}

.pages_contents > div {
  margin-bottom: 32px;
  text-align: left;
  padding: 0 70px 20px 70px;
}

.pages_contents a {
  color: $accent-color;
  text-decoration: none;
}

.pages_contents h1 {
  line-height: 150%;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 16px;
  text-align: center;
  /*font-style: italic;*/
  text-transform: uppercase;
}

.pages_contents h2 {
  font-size: 25px;
  line-height: 150%;
  font-weight: 900;
  margin-bottom: 16px;
  /*font-style: italic;*/
  text-transform: uppercase;
}

.pages_contents p {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: $text-color;
}

/* //////////////MODAL////////////////*/
.modal_wrapper {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: transparentize($background-color, .15);
  z-index: 20;
  transition: visibility 0s, opacity 0.2s linear;

  &.active {
    visibility: visible;
    display: flex;
    opacity: 1;
  }

  h1 {
    font-size: clamp(28px, 3vw, 36px);
    color: $text-color-strong;
    font-weight: 900;
    text-align: center;
    /*font-style: italic;*/
    text-transform: uppercase;
  }

  p {
    color: $text-color-strong;
  }

}

.modal_container {
  position: relative;
  align-self: center;
  overflow: scroll;
  padding: 50px 20px 50px 20px;
  max-width: 600px;
  width: 70vw;
  min-width: 260px;
  background-color: $modal_background;
  border-radius: 20px;
}

.modal_close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.74);
  background-image: url("../public/images/icons/cancel.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease-in-out;
}

.modal_close:hover {
  background-color: white;
}

.modal_container > .pages_contents {
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin: 0;
}

/* ///////////// Header ///////////// */
.header-component {
  backdrop-filter: blur(14px);
  filter: drop-shadow(0px -18px 34px rgba(0, 0, 0, 0.527));
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: transparentize($accent-color, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo {
  height: 100%;
  width: 20vw;
  min-width: 180px;
  position: absolute;
  left: 70px;
  background-size: contain;
  background: url(../public/brand/images/logo.png) no-repeat center;
  background-size: contain;
}

.header-logo:hover {
  cursor: pointer;
}

.burger-options {
  overflow: hidden;
  visibility: hidden;
  border-radius: 0 20px 20px 0;
  position: fixed;
  top: 0;
  width: 310px;
  left: -310px;
  height: 100vh;

  background-color: $sidebar-background;
  transition: 0.2s ease-in-out;
}
.options-header-logo{
  background: url(../public/brand/images/logo.png) no-repeat center/contain;
  width: 150px;
  height: 100%;
  position: absolute;
  right:15px;
}

.side_bar_section > .header-right {
  height: 70px;
  position: relative;
  right: 0;
  place-content: center;
}

.side_bar_section.login {
  padding: 0;
}

.slider_bottom {
  width: 70%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 30px;
}

.slider_bottom > .live_chat_btn {
  position: relative;
  width: 40px;
  height: 40px;
}

.slider_bottom .ft-imgs {
  position: absolute;
}

.burger-options.active {
  z-index: 15;
  visibility: initial;
  display: block;
  width: 310px;
  left: 0;

}

.side_bar_section {
  border: 1px solid transparentize($background-color, 0.30);
  min-width: 200px;
  background-color: $sidebar-section-background; //rgba(0, 23, 23, 0.77);
  border-radius: $border-radius;
  padding-left: 10px;
  margin: 0 10px 10px 10px;
}

.side_bar_section > .btn {
  width: 95%;
  border-bottom: 1px solid transparentize($background-color, 0.20);
  padding-bottom: 25px !important;
  padding-top: 25px !important;
}

.side_bar_section > .btn:last-child {
  border-bottom: none;
}

.bg-fixed {
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparentize($background-color, 0.20);
  overflow: hidden;
  z-index: 5;
  transition: 0.3s ease-in-out;
}

.bg-fixed.active {
  visibility: initial;
  opacity: 1;
}

.burger-options-header {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.header-right {
  position: absolute;
  height: 100%;
  right: 50px;
  top: 0;
  display: flex;
  align-items: center;
}

.header-pages {
  display: flex;
}

.btn.header {
  display: flex;
  padding: 11px 10px;
  text-decoration: none;
  color: $text-color-strong;
  text-transform: capitalize;
  /*font-style: italic;*/
  font-size: 18px;
  line-height: 1;
  font-weight: 900;
  align-items: center;
  justify-content: space-between;
}

.btn.header:hover {
  color: $accent-color;
}

.btn.st {
  display: flex;
  align-items: center;
  border: 2px solid $accent-color;
  color: $accent-color;
  border-radius: $border-radius;
  max-width: 250px;
}

.btn.st:hover {
  color: $background-color;
  background-color: $accent-color;
  border-color: $accent-color;
  .lb-img{
    @include recolor(black)
  }
}

.header-left {
  position: absolute;
  margin-left: 15px;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
}

.filter_header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 5px solid $accent-color;
}

.filter_header > input {
  outline: none;
  font-size: 16px;
  line-height: 140%;
  width: 80%;
  opacity: 1;
  overflow-x: hidden;
  color: $text-color;
  padding: 0 0 0 20px;
  border: none;
  background-color: initial;
  height: 56px;
  border-radius: 0;
  font-weight: 700;
}

.filter_button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  filter: invert(0.9);
  background: url(../public/images/icons/magnifier.png) no-repeat center center/cover;

}

.filter_button.close {
  background-image: url("../public/images/icons/cancel.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.filter_button.inp {
  cursor: default;
  display: block;
  position: relative;
  right: 0;
  filter: invert(0.7);
  margin-left: 40px;
  background: url(../public/images/icons/magnifier.png) no-repeat center center/cover;
}

.filter_div {
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: $background-color;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.filter_div.active {
  display: block;
  visibility: visible;
  opacity: 1;

}

/* //////  LIVE Chat /////// */
.chat_wrapper {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lcwindowBody {
  background: #fff;
  color: #333333;
  margin: 0;
  padding: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
}

.chat_wrapper h3 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $accent-color;
  display: block;
  margin: 5px 0 0 15px;
  float: left;
}

.hide {
  display: none;
}

.lcwindowBody p {
  margin: 0 0 15px 0;
  padding: 0;
}

/**
 * General submit classes
 */

.submit-lg {
  display: block;
  color: #fff;

  height: 30px;
  width: 120px;
  border: 0;
  margin: 0;
}

.submit-sm {
  color: whitesmoke;
  background-color: rgb(51, 51, 78);
  border-radius: 20px;
  height: 30px;
  width: 80px;
  border: 0;
  margin: 0;
  cursor: pointer;
  margin-bottom: 5px;
}

/**
 * Classes for styling the alert / notification box
 */

.alert-container {
  background: $accent-color;
  width: 100%;
  height: 70px;
  margin-left: -5px;
}

.alert-content {
  position: absolute;
  margin-top: 50%;
  border-radius: 20px;
  margin-left: -3px;
  display: flex;
  justify-content: center;
  background-color: $text-color-strong;
  width: 100%;
  font-size: 18px;
}

.chat-history {
  min-height: 400px;

  overflow-y: scroll;
  overflow-x: hidden;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}

.msg {
  margin: 3px 0px 3px 1px;
  border-radius: 20px;
  width: -moz-fit-content;
  display: flex;
  max-width: 60%;
  width: fit-content;
  font-size: 13px;
  padding: 19px;
  background-color: rgba(176, 237, 255, 0.568);
  color: rgb(0, 0, 0);
  display: flex;
  flex-wrap: wrap;
}

.msg.me {
  background-color: rgba(194, 250, 139, 0.466);
  align-self: flex-end;
}

/**
 * The chat form consists of a text input and submit button
 */
.input-container {
  outline: hidden;
  width: 230px;
}

.chat-form {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.chat_bottom {
  display: flex;
}

.input-content {
  padding-right: 0px;
  height: 50px;
}

.input-content input {
  width: 93%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: none;
  border-radius: 20px;
  padding-left: 10px;
  border: 0;
  height: 50px;
}

.chat-form .submit {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
  background-color: rgb(219, 217, 217);
  background-image: url(../public/images/icons/send.png);
  cursor: pointer;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 0;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.chat-form .submit:hover {
  background-color: rgb(212, 212, 255);
}

/**
 * CSS for the stub in the cashier page
 */
#lcv2Stub {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1%;
  z-index: 1000;
  width: 30%;
  max-width: 300px;
}

#lcv2Stub #lcv2-btn {
  background: #3b9ff3;
  border: 1px solid #3596e7;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  line-height: 15px;
  vertical-align: middle;
  font-size: 12px;
  text-shadow: none;
  box-shadow: none;
  background-image: none;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 14px;
  width: 100%;
}

#lcv2Stub p {
  margin: 0 0 15px 0;
  padding: 0;
}

#lcv2Stub.open #lcv2-btn {
  width: 300px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#lcv2Stub #lcv2-alerts {
  display: none;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  border-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 300px;
  padding: 15px;
  font-size: 12px;
  color: #333;
}

#lcv2Stub.open #lcv2-alerts {
  display: block;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

#lcv2Stub.open #lcv2-alerts .submit-sm {
  background: #3b9ff3;
  border: 1px solid #3596e7;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  line-height: 15px;
  vertical-align: middle;
  font-size: 12px;
  text-shadow: none;
  box-shadow: none;
  background-image: none;
  color: rgb(22, 21, 21);
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 14px;
  width: auto;
  -webkit-transition: all 0.1s linear;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: linear;
  -webkit-transition-delay: initial;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  transition-delay: initial;
}

#lcv2Stub.open #lcv2-alerts .submit-sm:hover {
  background: #6ab1f2;
  border: 1px solid #64a8e8;
}

/* //////  Game Separator /////// */
.game_separator {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
}

.games_item {
  margin: 20px;
  position: relative;
  height: 14vw;
  width: 14vw;
  min-height: 150px;
  max-height: 350px;
  min-width: 150px;
  max-width: 350px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.games_item img {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  transition: 0.3s ease-in-out;
  z-index: -1;
}

/* ///////////// Language Options ///////////// */
.language-options {
  position: fixed;
  left: 39%;
  top: 40%;
  z-index: 12;
  /* bottom: 63px; */
  width: 300px;
  height: 300px;
  background-color: $modal_background;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  place-content: center;
}

.live_chat {
  position: fixed;
  overflow: hidden;
  padding: 5px 0 5px 5px;
  bottom: 15px;
  width: 300px;
  right: 19px;
  z-index: 12;
  height: 500px;
  background-color: #e7e7e7;
  border-radius: 20px;
}

.chat_alert {
  display: none;
}

.chat_alert.active {
  display: block;
  width: 100%;
  border-radius: 20px;
  margin-left: -5px;
  position: absolute;
  background-color: darkcyan;
}

.langOptionsEachWrapper {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 80px;
  /* background-color: yellowgreen; */
  margin: 5px;
}

.langOption {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: transparentize($accent-color, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  align-self: center;
  transition: 0.3s ease-in-out;
}

.langOption:hover {
  background-color: transparentize($accent-color, 0.7);
}

.langOption.active {
  background-color: $accent-color;
}

.langOptionsEachWrapper > div img {
  object-fit: cover;
  border-radius: 500px;
  width: 30px;
  height: 30px;
}

.langOptionsEachWrapper span {
  cursor: default;
  margin-top: 5px;
  font-size: 12px !important;
  line-height: 120%;
  font-weight: 900;
  color: $text-color-strong !important;
  font-family: $font-family;
}

/* ///////////// Search Bar ///////////// */
.search_outer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.search_bar_wrapper {
  width: 100%;
  max-width: 2000px;
  display: flex;
  justify-content: center;
}

.search_button {
  position: relative;
  cursor: pointer;
  width: 180px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 20px;
  height: 48px;
  display: flex;
  margin-right: 12px;
  background: $background-color;
  border: 2px solid $accent-color;
  border-radius: $border-radius;
  color: $text-color;
  z-index: 6;
}

.search_inputs {
  display: flex;
  align-self: center;
}

.search_button:hover {
  border: 2px solid transparentize($accent-color, 0.3);
}

.search_button p::after {
  content: "";
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../public/images/icons/arrow-up.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  filter: invert(97%) sepia(45%) saturate(541%) hue-rotate(172deg) brightness(93%) contrast(87%);
  transform: translateY(-50%);
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}

.search_button.open p::after {
  transform: rotate(180deg);
}

.search_inner {
  display: none;

}

.search_button.open {
  border-radius: $border-radius;
  border-left: 2px solid $accent-color;
  border-right: 2px solid $accent-color;
  border-top: 2px solid $accent-color;
}

.search_inner.open {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 20px;
  display: block;
  position: absolute;
  border-radius: $border-radius;
  top: 40px;
  left: -2px;
  width: 100%;
  height: 280px;
  background: $background-color;
  z-index: 5;
  border-left: 2px solid $accent-color;
  border-right: 2px solid $accent-color;
  border-bottom: 2px solid $accent-color;
}

::-webkit-scrollbar-track {
  background-color: initial;
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  visibility: hidden;
}

.search_providers::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  background-color: transparentize($accent-color, 0.75);
  visibility: visible;
}

.search_providers::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $accent-color;
  visibility: visible;
}

.search_providers {
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  text-align-last: left;
  height: 100%;
  overflow-y: scroll;
}

.search_providers span {
  margin-bottom: 8px;
}

.search_providers span:hover {
  color: $accent-color;
}

.search_bar {
  margin: 55px 0 50px 1.5%;
  width: 89%;
  display: flex;
  overflow-x: visible;
  position: relative;
}

.search_icons {
  height: 110px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-width: 1000px;

  img {
    width: 50px;
    height: 50px;
    filter: invert(84%) sepia(100%) saturate(0) hue-rotate(
                    55deg
    ) brightness(103%) contrast(101%);
  }

  span {
    font-family: $font-family-promo;
    margin-top: 8px;
    line-height: 138%;
    font-weight: 600;
    text-align: center;
    color: $text-color-strong;
  }
}

.search_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    transform: scale(1.2);

    img {
      @include recolor($accent-color, 0.8);
    }

    span {
      color: $accent-color;
    }
  }
}


/* ///////////// Bonus Slider ///////////// */
.bonus_slider {
  position: relative;
  width: 100%;
  margin-top: 70px;
  overflow: hidden;
  /*background: url(../public/images/bg-dashboard.jpg) no-repeat 50% 50% / cover;*/
  box-shadow: inset 0 -70px 81px 6px $background-color;

}

.bonus_slider:before {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  background-size: contain;
  background: $bonus-slider-background;
  top: -0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: .6;
}

.carousel-next {
  margin-right: 20px
}

.carousel-prev {
  margin-left: 20px
}

.carousel-next img {
  filter: invert(0.8);
  max-width: 50px;
  max-height: 50px;
  min-width: 15px;
  min-height: 15px;
  width: 2vw;
  height: 2vw;
}

.carousel-prev img {
  max-width: 50px;
  max-height: 50px;
  min-width: 15px;
  min-height: 15px;
  width: 2vw;
  height: 2vw;
  filter: invert(0.8);
  transform: rotate(180deg);
}

.bonus_slider > div {
  height: 25vw;
  min-height: 250px;
  max-height: 500px;
}

.bonus_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vw;
  min-height: 250px;
  max-height: 500px;
  .bonus_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    .bonus_pic{
      height: 30%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: end;
      img{
        height: 100%;
        margin-bottom: 15px;
      }
    }
  }
}

.b_t {
  margin: 0 60px 0 0;
  display: flex;
  flex-direction: column;
}

.bonus_wrapper span {
  font-size: clamp(20px, 3.8vw, 60px);
  text-align: left;
  font-weight: 900;
  text-shadow: 0 4px 6px transparentize(mix($accent-color, $background-color, 7%), 0.7);
  color: $text-color-strong;
  /*font-style: italic;*/
  text-transform: uppercase;
  display: inline;
  font-family: $font-family-promo;
}

.bonus_wrapper span.s {
  font-size: clamp(18px, 3vw, 60px);
}

.bonus_wrapper span.c {
  font-size: clamp(30px, 4vw, 60px);
  color: $accent-color;
}

.btn.bonus {
  margin-top: 0;
  margin-right: 0;
  font-family: $font-family-promo;
  background-color: $accent-color;
  color: $background-color;
  border-radius: $border-radius;
  font-size: clamp(15px, 3vw, 25px);
  min-width: 100px;
  min-height: 50px;
  max-height: 80px;
  max-width: 300px;
  height: 5vw;
  width: 25vw;
  font-weight: 900;
}

.btn.bonus:hover {
  transform: scale(1.1);
}

.collapse {
  margin-bottom: 12px !important;
  position: relative;
  background-color: transparentize(invert($background-color), 0.97);
  border: 1px solid transparentize($background-color, 0.80);
  border-radius: 16px;
  transition: border 0.2s ease;
  padding: 0 20px 0px 20px;
  overflow: hidden;
}

.collapse h4 {
  cursor: pointer;
  width: 95%;
  font-size: 16px;
  color: transparentize($accent-color, 0.2);
  /*font-style: italic;*/
  text-transform: uppercase;
  font-family: $font-family;
  font-weight: 500;
}

.collapse > .p_content {
  max-height: 0px;
  transition: 1s ease-in-out;
}

.collapse.open > .p_content {
  max-height: 3000px;
  transition: 1s ease-in-out;
}

.p_content p:last-child {
  padding-bottom: 20px;
}

.collapse h4::after {
  content: "";
  position: absolute;
  top: 30px;
  right: 15px;
  width: 16px;
  height: 16px;
  background-image: url(../public/images/icons/arrow-up.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  transform: translateY(-50%);
  transition: 0.4s ease-in-out;
  @include recolor($accent-color, 0.8);
}

.collapse.open h4::after {
  content: "";
  position: absolute;
  top: 25px;
  right: 15px;
  width: 16px;
  height: 16px;
  background-image: url(../public/images/icons/arrow-up.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  @include recolor($text-color, 0.8);
  transform: translateY(-50%);
  transform: rotate(180deg);
}

.game_scale_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 15px;
}

.collapse.open > h4 {
  color: $text-color-strong;
}

/* ///////////// Footer ///////////// */
.footer-wrapper {
  z-index: 2;
  filter: drop-shadow(0px 1px 9px transparentize($background-color, 0.5));
  width: 100%;
  /*border-radius: 30px 30px 0 0;*/
  background-color: $footer-background;
}

.footer-inner {
  padding: 15px 15px 0;
  margin-bottom: 100px;
}

.footer-section {
  display: flex;
}

.footer-section.top {
  margin-top: 15px;
  position: relative;
  justify-content: center;
}

.footer-section.images {
  margin: 25px 0 30px 0;
  padding: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  border-style: solid;
  border-width: 1px;
  border-image: $footer-hr-border;
  justify-content: center;
}

.footer-section.pages {

  justify-content: space-evenly;

  > div {
    display: flex;
    flex-direction: column;
    text-align-last: left;

    > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      text-align-last: left;
      margin-bottom: 20px;
    }

    .btn {
      text-decoration: none;
      font-style: normal;
      font-size: 16px;
      padding: 0;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 10px;
      color: $accent-color;
      height: 30px;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }


  }
}


.footer-section span {
  text-transform: uppercase;
  margin-bottom: 30px;
  cursor: default;
  font-size: 16px;
  line-height: 120%;
  font-weight: 900;
  /*font-style: italic;*/
  color: $accent-color;
  display: block;
}

.footer-imgs {
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 0 15px 0 15px;

  img {
    object-fit: scale-down;
    width: 100px;
    filter: grayscale(1);
    opacity: 70%;
  }
}

.chat_cancel {
  position: absolute;
  top: 0;
  right: 0;
  margin: 11px 20px 0 0;
  width: 15px;
  height: 15px;
  background-image: url(../public/images/icons/cancel.png);
  background-size: cover;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
}

.chat_cancel:hover {
  transform: scale(1.1);
  opacity: 1;
}

.live_chat_btn {
  margin: 0 10px 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.301);
  border-radius: 30px;
  width: 150px;
  height: 35px;
  border: 2px solid transparentize($accent-color, 0.7);
}

.live_chat_btn:hover {
  border: 2px solid $accent-color;
}

.footer-section > .language {
  cursor: pointer;
  margin: 0 50px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(7, 6, 6, 0.575);
  border-radius: 30px;
  width: 150px;
  height: 35px;
  border: 2px solid rgb(68, 67, 67);
}

.btn.footerPages {
  font-size: 12px;
}

.btn.top {
  font-size: 14px;
}

.btn.lang {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
}

.btn.chat {
  color: $accent-color;
  margin: 0;
  text-transform: capitalize;
}

.ft-imgs {
  object-fit: cover;
  width: 25px;
  height: 25px;
}

.ft-imgs.chat {
  object-fit: contain;
  background-image: url("../public/images/icons/chat.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0;
  @include recolor($accent-color, 0.8);
}

.hamburger-react > div > div {
  background: $accent-color !important;
}

.side_bar_section > .btn img {
  @include recolor($text-color)
}

.side_bar_section > .btn:hover img {
  @include recolor($accent-color)
}

@media screen and (max-width: 1500px) {
  .search_bar {
    flex-direction: column;

  }
  .search_inputs {
    display: none;
  }
}

@media screen and (max-width: 1350px) {
  .search_bar {
    overflow-x: scroll;
  }

  /*.search_inputs {*/
  /*    display: none;*/
  /*}*/
}

@media screen and (max-width: 1300px) {
  .header-pages {
    display: none;
  }


  .promo_right img {
    width: 100%;
    height: 80%;
    position: absolute;
    left: 20px;
    top: 10%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1000px) {

  .footer-section.pages > .collapse {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .search_bar {
    width: 100%;
  }

  .slider-title {
    top: -24px;
  }

  .swiper_item_jackpot {
    height: 22vw;
    width: 22vw;
  }

  .swiper_item_carousel {
    height: 30vw;
    width: 30vw;
  }

  .jackpot_slider {
    display: flex;
    flex-direction: column;
  }

  .jackpot_left {
    width: 100%;
    margin-bottom: 20px;
  }

  .jackpot_right {
    display: flex;
    width: 100%;
    position: relative;
  }

}

@media screen and (max-width: 1000px) {
  .arrow.basic {
    display: none;
  }

  .footer-section-desktop {
    display: none !important;
  }

  .footer-section.pages > div > div {
    margin: 0;
  }

  .footer-section.pages > div > div :last-child {
    margin-bottom: 20px !important;
  }

  .footer-section.pages {
    flex-direction: column;
  }

}

@media screen and (max-width: 900px) {
  .promo_right {
    overflow: visible;
    width: 40%;
  }

  .promo_right img {
    width: 100%;
    height: 100%;
    position: relative;
    left: unset;
    top: unset;

  }

  .promotions_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .promotion_section {
    width: 70vw;
  }
}

@media screen and (max-width: 800px) {
  .header-logo {
    position: relative;
    left: 0px;
    background-size: contain;
    background: url(../public/brand/images/logo.png) no-repeat center;
    background-size: contain;
  }
  .footer-section > .language {
    margin: 0;
  }

  .winner_board {
    min-width: 300px;
  }

  .board_center_line {
    right: 35%;
  }

  .b-first {
  }

  .board_content > span:last-child {
    width: 35%;
  }

  .winner_name {
    padding-left: 10px;
    width: 90%;
  }

  .winner_value {
    padding-left: 10px;
  }

  .winner_points {
    padding-left: 10px;
  }

  .board_content h3 {
    left: -87px;
    top: 35%;
  }

  .slider-title {

    top: -20px;
  }

  .arrow {
    display: none;
  }

  .swiper_item_jackpot {
    height: 30vw;
    width: 30vw;
  }

  .swiper_item_carousel {
    height: 30vw;
    width: 30vw;

  }


  .footer-inner {
    margin-bottom: 130px;
  }

  .mobil-header-right {
    position: fixed;
    background: rgba(18, 19, 20, 0.938);
    backdrop-filter: blur(14px);
    top: 92vh;
    height: 55px;
    min-width: 200px;
    width: 65vw;
    padding: 0px 15px 0 15px;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
  }

  .header-right {
    right: 0;
    margin: 0;
    width: 100%;
    justify-content: space-around;
  }

  .header-right > .btn {
    margin-right: 0;
    margin-left: 0;
  }

  .header-left {
    z-index: 2;
  }

  .pages_contents {
    margin: 50px 0 50px 0px;
    width: 95%;
    border-radius: 30px;
  }

  .footer-section.top > div {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .pages_options {
    padding: 50px 20px 50px 20px;
    margin: 22px 0px 0px 0;
    min-width: 60%;
    padding: 0px 10px 5px 10px;
  }

  .pages_contents > div {
    padding: 0 10px 50px 10px;
  }

  .collapse {
    padding: 0 10px 0px 10px !important;
    overflow: hidden;
    cursor: pointer;
  }
}

@media screen and (max-height: 700px) {

  .mobil-header-right {
    position: fixed;
    background: rgba(18, 19, 20, 0.938);
    backdrop-filter: blur(14px);
    top: 90vh;
    height: 55px;
    min-width: 200px;
    width: 65vw;
    padding: 0px 15px 0 15px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
  }
  .header-right {
    width: 100%;
    justify-content: space-evenly;
    right: unset;
  }

}

@media screen and (max-height: 500px) {

  .mobil-header-right {
    top: 80vh;
  }

}

@media screen and (max-width: 600px) {
  .game_separator {
    padding: 0px;
  }
  .modal_container {
    max-height: 500px;
  }

  .modal_close {
    right: 2px;
    top: 2px;
  }

  .swiper_item_jackpot {
    height: 30vw;
    width: 30vw;
  }

  .swiper_item_carousel {
    height: 30vw;
    width: 30vw;

  }

}

@media screen and (max-width: 501px) {
  .language-options {
    left: 20%;
  }
  .slider-title > span {
    margin-left: 9vw;
  }
  .promo_right img {
    width: 100%;
    height: 80%;
    position: absolute;
    left: 10px;
    top: 10%;
  }

  .slider-title {
    top: -11px;
  }

  .swiper_item_jackpot {
    height: 40vw;
    width: 40vw;
  }

  .swiper_item_carousel {
    height: 40vw;
    width: 40vw;

  }
  .middle_bg_pic:before {
    transform: scale(2);
    background-size: contain;
  }
  .bonus_slider:before {
    transform: scale(1.5);
  }
}

@import "brand";