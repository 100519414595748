.bonus_slider:before{
    background-size: cover;
}
.footer-section.images {
    border-image: linear-gradient(0.25turn, #0c0101, rgba(248, 18, 18, 0.51), #0c0101) 1 0 1 0;
}
.search_icon{
    @include recolor(#ffe4e4)
}
.btn.bonus {

}
@media screen and (max-width: 600px) {
    .btn.bonus {
    margin-top: 25px;
    }
    .bonus_wrapper{
        img{
            position: absolute;
            right: initial !important;
            top: 10%;
            height: 25%;
        }
    }
}